<template>
    <div class="local-back" :class="position === 'top' ? 'local-back-top' : ''" @click="toBack">
        <img src="../static/image/icon/icon_fh.png"/>
    </div>
</template>

<script>
    export default {
        name: "Back",
        props: {
            position: {
                type: String,
                default: 'bottom'
            },
            urlName: {
                type: String,
                default: ''
            }
        },
        data() {
            return {}
        },
        methods: {
            toBack() {
                this.urlName ? this.$router.push({name: this.urlName}) : this.$router.back();
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/scss/index";

    .local-back {
        position: fixed;
        right: toRem(30);
        bottom: toRem(140);
        width: toRem(80);
        height: toRem(80);
        border-radius: 50%;
        background: rgba(0, 0, 0, .7);
        &.local-back-top{
            position: fixed;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
</style>