import core from '../core/index.js'
import {getWXConfig} from '../server/index.js'
// import wx from "weixin-js-sdk";

function init() {
    const userId = core.cookie.getStorage('sessionId')
    if (userId) {
        return userId
    } else {
        getUserId()
    }
}

 function getUserId() {
    const _url = window.location.href;
     // let _url = /iPhone|mac|iPod|iPad/i.test(navigator.userAgent) ? url : window.location.href.split('#')[0];
     (async function () {
         const u = encodeURIComponent(_url)
         const data = await getWXConfig({url: u})
         if (data && data.Data) {
             const _data = data.Data;
             window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${_data.appid}&redirect_uri=${_url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
         }
     })()
}

export default {
    init
}

