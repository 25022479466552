<template>
    <div class="views-apply">
        <div class="apply-content">
            <div class="apply-form">
                <h4 class="title">报名参赛</h4>
                <van-form @submit="onSubmit" ref="form">
                    <van-field
                            v-model="form.area"
                            is-link
                            readonly
                            required
                            :label="form.CateId === '1' ? '工作/居住区' : '爱教基地所在区'"
                            :placeholder="form.CateId === '1' ? '请选择工作/居住区域' : '爱教基地所在区'"
                            :rules="[{ required: true, message: '请选择' }]"
                            @click="showStreet = true"
                    />
                    <van-field v-if="form.CateId === '2'" v-model="form.TeamTitle" name="TeamTitle" label="爱教基地全称"
                               maxlength="40"
                               required
                               :rules="[{ required: true, message: '请填写爱教基地全称' }]"
                               placeholder="爱教基地全称（必填）"/>
                    <van-field
                            v-model="form.Name"
                            name="Name"
                            label="姓名"
                            required
                            placeholder="姓名（必填）"
                            maxlength="5"
                            :rules="[{ required: true, message: '请填写姓名' }]"
                    />
                    <van-field
                            v-model="form.Mobiles"
                            type="number"
                            name="Mobiles"
                            label="手机号码"
                            required
                            placeholder="手机号码（必填）"
                            :rules="[{ required: true,pattern: mobilePattern, message: '请填写正确的手机号码' }]"
                    />
                    <van-field v-if="form.CateId === '1'" v-model="form.Position" name="Position" label="职业"
                               is-link
                               readonly
                               maxlength="10"
                               @click="showSelectJob = true"
                               placeholder="请填写职业"/>
                    <van-field v-if="form.CateId === '1'" type="number" v-model="form.Ages" name="Ages" label="年龄"
                               maxlength="3"
                               required
                               placeholder="请填写年龄" :rules="[{ required: true, pattern, message: '请输入正确的年龄' }]"/>
                    <!--                    <van-field v-model="form.email" name="电子邮箱" label="电子邮箱" placeholder="请填写电子邮箱"/>-->

                </van-form>
                <van-popup v-model="showStreet" round position="bottom">
                    <van-cascader
                            v-model="streetValue"
                            title="请选择所在地区"
                            :options="options.street"
                            :field-names="{ text: 'Name', value: 'Id',children: 'children'}"
                            @close="showStreet = false"
                            @change="changeArea"
                            @finish="subStreet"
                    />
                </van-popup>
                <van-popup v-model="showSelectJob" round position="bottom">
                    <van-picker
                            title=" "
                            show-toolbar
                            :columns="options.job"
                            @confirm="confirmJob"
                            @cancel="showSelectJob = false"
                    />
                </van-popup>
            </div>
            <div class="agree-rules">
                <van-checkbox shape="square" v-model="checked" checked-color="#faf3af">我已阅读并同意
                    <a class="to-rules" @click="toRules()" href="javascript:">活动规则</a>，马上参赛
                </van-checkbox>
            </div>
            <div class="submit-box">
                <div class="submit-button" @click="subForm">确认提交</div>
            </div>
        </div>
        <Back urlName="Home"></Back>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Form, Checkbox, Cascader, Toast} from 'vant';
    import initUserInfo from '../utils/initUserInfo';
    import {getArea, getStreet, applyActivity, hasUpFileInfo} from '../server'
    import getStrParams from "../utils/getStrParams";
    import core from '../core/index'
    import _ from "lodash";
    import Back from "../components/Back";
    import initWXAPI from "../utils/initWXAPI";
    import WXShare from "../utils/WXShare";

    Vue.use(Form);
    Vue.use(Checkbox);
    Vue.use(Cascader);
    export default {
        name: "Apply",
        components: {Back},
        data() {
            return {
                form: {
                    CateId: '1',
                    SessionId: '',
                    Name: '',
                    Position: '',
                    Ages: '',
                    area: '',
                    districtid: '', //区编号
                    subdistrictid: '', //街道编号
                    Mobiles: '',
                    TeamTitle: '',
                },
                streetValue: '',
                checked: false,
                showStreet: false,
                showSelectJob: false,
                pattern: /^(\s*|[1-9]\d?|1[01]\d|120)$/,
                mobilePattern: /^(1\d{10})$/,
                // pattern: /\d{2}/,
                options: {
                    street: [],
                    job: [
                        "农林牧渔业",
                        "采掘业",
                        "制造业",
                        "电力燃气及水的生产和供应业",
                        "建筑业",
                        "交通运输仓储和邮政业",
                        "信息传输计算机服务和软件业",
                        "批发和零售业",
                        "住宿和餐饮业",
                        "金融业",
                        "房地产业",
                        "租赁和商务服务业",
                        "科学研究和技术服务业",
                        "地质勘察业以及环境和公共设施管理业",
                        "居民服务和其他服务业",
                        "教育",
                        "卫生和社会保障以及社会福利业",
                        "文化和体育以及娱乐业",
                        "公共管理和社会组织",
                        "国际组织",
                        "退休",
                        "其他"
                    ]
                }
            }
        },
        methods: {
            async onSubmit(values) {
                console.log(values)
                if (!this.checked) {
                    Toast('请先阅读并同意活动规则！')
                    return;
                }
                if (this.form.CateId === '2') this.form.subdistrictid = '0'
                Object.assign(this.form, values)
                const params = _.cloneDeep(this.form);
                delete params['undefined']
                delete params.area
                const data = await applyActivity(params)
                if (data.Data && data.Data.Id) {
                    Toast('报名成功');
                    this.$router.push({
                        path: 'uploadWorks',
                        query: {type: this.form.CateId, id: data.Data.Id}
                    }).catch(() => {
                    })
                } else if (data.ErrorMsg) {
                    Toast(data.ErrorMsg)
                } else {
                    Toast('报名失败，请稍后重试!')
                }
            },
            subStreet({selectedOptions}) {
                this.showStreet = false;
                this.form.area = selectedOptions.map((option) => option.Name).join('/');
                this.form.districtid = selectedOptions[0].Id;
                this.form.subdistrictid = selectedOptions[1] && selectedOptions[1].Id;
            },
            // 改变区域，刷新街道列表
            async changeArea(item) {
                if (this.form.CateId === '2') return
                const data = await getStreet({DistrictId: item.value})
                this.options.street.filter(d => item.value === d.Id ? d.children = data.Data.list : '')
            },
            confirmJob(value) {
                this.form.Position = value
                this.showSelectJob = false;
            },
            subForm() {
                this.$refs.form.submit()
            },
            // 获取区域列表
            async getAreaOptions() {
                const data = await getArea({})
                if (data.Data.list.length && this.form.CateId === '1') {
                    data.Data.list.map(d => {
                        d.children = []
                    })
                }
                this.options.street = data.Data.list;
            },
            toRules() {
                // 跳转 活动规则
                this.$router.push({path: 'rules', query: {type: this.form.CateId}}).catch(() => {
                })
            },
            async getApplyStatus() {
                const data = await hasUpFileInfo({SessionId: this.form.SessionId})
                if (data.ErrorMsg) {
                    Toast(data.ErrorMsg)
                    return;
                }
                const _data = data.Data;
                if (_data && _data.RetType) {
                    switch (_data.RetType) {
                        case 1:
                            this.initPage()
                            break;
                        case 2:
                            this.$router.replace({path: 'userCenter'})
                            break;
                        case 3:
                            // this.$router.replace({path: 'userCenter'})
                            // break;
                            this.$router.replace({path: 'uploadWorks', query: {type: this.form.CateId, hasWorks: '1'}})
                            break;
                        case 4:
                            this.$router.replace({path: 'uploadWorks'})
                            break;
                        default:
                            this.initPage()
                            break;
                    }
                } else {
                    Toast(_data.ErrorMsg)
                }
            },
            initPage() {
                this.getAreaOptions()
                // initUserInfo.init(this.$store.state.url)   //微信授权登录
                WXShare.localShare()  // 微信分享
            }
        },
        created() {
            this.form.CateId = '1'
            this.form.SessionId = core.cookie.getStorage('sessionId')
            this.getApplyStatus()
            // this.initPage()
            // process.env.NODE_ENV === 'development'
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/scss/index";

    .views-apply {
        background: url("../static/image/ny_bg.png") no-repeat top left #be0b15;
        background-size: contain;
        padding-top: toRem(400);
        min-height: 100vh;
        box-sizing: border-box;

        .apply-content {
            position: relative;

            .apply-form {
                background: #fff;
                margin: toRem(12);
                border-radius: toRem(12);
                padding: toRem(30) toRem(40);

                .title {
                    color: $basicFontColor;
                    text-align: center;
                    font-size: toRem(32);
                    background: url("../static/image/icon/bg_iconbt.png") no-repeat center center;
                    background-size: auto toRem(65);
                    font-weight: normal;
                    margin-bottom: toRem(20);
                }
            }

            .van-checkbox__label {
                color: #faf3af;
            }

            .agree-rules {
                color: #faf3af;
                font-size: toRem(22);
                padding: toRem(20);

                .to-rules {
                    color: #fff;
                }
            }

            .submit-box {
                padding: 0 toRem(40) toRem(40);

                .submit-button {
                    height: toRem(110);
                    line-height: toRem(110);
                    text-align: center;
                    font-size: toRem(34);
                    background: url("../static/image/btn_ju.png") no-repeat top left;
                    background-size: contain;
                }
            }
        }
    }
</style>
<style>
    .views-apply .apply-content .van-checkbox__icon {
        height: auto;
    }

    .views-apply .apply-content .van-checkbox__icon .van-icon {
        font-size: 0.75rem;
        vertical-align: middle;
    }

    .views-apply .apply-content .van-checkbox__label {
        color: #faf3af;
        vertical-align: middle;
    }

    .views-apply .apply-content .item-label {
        padding-left: 0.3rem;
    }

    .views-apply .apply-content .item-label :before {
        margin-right: 0.3rem;
        content: '。';
        color: transparent;
        display: inline-block;
        width: 0.4rem;
        background-image: linear-gradient(#ffdc34, #ffaf00);
        border-radius: 0.2rem;
    }
</style>